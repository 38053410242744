<template>
  <div class="view pa24">
    <div class="d-flex">
      <div class="d-flex">
        <div style="display: flex">
          <el-input
              class="w220 mr10"
              placeholder="标题"
              v-model="filterName"
              clearable
          />
          <el-select
              clearable
              v-model="zsType"
              class="w220 mr10"
              placeholder="请选择招商类型"
          >
            <el-option
                v-for="(item) in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </div>
        <el-button type="primary" @click="filterNames">搜索</el-button>
      </div>
      <div style="margin-left: auto">
        <!-- <el-button class="ma mr10 mb10" @click="delAll">批量删除</el-button> -->
        <el-button class="ma" type="primary" @click="centerDialogVisible = true;upDate=1"
        >+ 发布动态</el-button
        >
      </div>
    </div>
    <commonTable
        :tableData="tableData"
        @handleSelectionChange="handleSelectionChange"
        :total="total"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
        :currentPage="currentPage"
        :loading="loading"
    >
      <template v-slot:table>
         <el-table-column type="selection" />
        <el-table-column prop="title" align="center" label="动态标题" />
        <el-table-column  align="center" label="缩略图">
          <template slot-scope="scope">
            <el-image
                style="width: 80px; height: 40px"
                :src="scope.row.thumbnail"
                :preview-src-list="srcList">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="type" align="center" label="招商类型" />
        <el-table-column prop="userName" align="center" label="发布人" />
        <el-table-column prop="createTime" align="center" label="发布时间" />
<!--        <el-table-column prop="createTime" align="center" label="发布内容" >-->
<!--          <template slot-scope="scope">-->
<!--            <div v-html="scope.row.content"></div>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" plain @click="viewDetails(scope.row)"
            >查看详情</el-button
            >
            <el-button type="primary" plain @click="open(scope.row)"
            >编辑</el-button
            >
            <customPopconfirm
                class="ma ml10 mb10"
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm="del(scope.row.circleAttractId)"
                title="确认删除？">
              <el-button type="danger" slot="reference" plain>删除</el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
        title="发布动态"
        :visible.sync="centerDialogVisible"
        @close="close"
        :modal-append-to-body="false"
        width="50%"
        center
    >
      <el-form
          ref="ruleForm" :model="ruleForm" :rules="rules" label-width="auto" class="view pa24" label-position="left"
      >
        <el-form-item prop="title" label="动态标题">
          <el-input
              clearable
              v-model="ruleForm.title"
              style="width: 100%"
              placeholder="请输入动态标题"
              maxlength="50"
              show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item prop="type" label="招商类型">
          <el-select
              clearable
              v-model="ruleForm.type"
              style="width: 100%"
              placeholder="请选择招商类型"
          >
            <el-option
                v-for="(item) in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="goodPhoto" label="缩略图">
          <p class="ma mb10" style="color: rgb(150, 150, 150)">
            图片上传比例为5:3，支持格式png、jpg、jpeg，大小不超过1M
          </p>
          <div style="display: flex">
            <el-upload
                :action="uploadUrl"
                :class="{
              disabled: uploadDisabled,
            }"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :on-success="goodsUpload"
                :before-upload="beforeAvatarUploadImg"
                :file-list="ruleForm.goodPhoto"
                :limit="1"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog
                :visible.sync="dialogVisible"
                append-to-body
                :modal-append-to-body="false"
            >
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </div>
        </el-form-item>
        <el-form-item label="发布内容" prop="details">
          <!-- //富文本 -->
          <quill-editor
              class="ql-editor"
              style="height: 100%"
              v-model="ruleForm.details"
              ref="myQuillEditor"
              @ready="ready($event)"
              :options="editorOption"
              @change="onEditorChange($event)"
          />
          <span class="wordNumber">{{TiLength}}/10000</span>
        </el-form-item>
<!--        <el-form-item label="是否展示单位信息" prop="ifShow">-->
<!--          <el-switch-->
<!--              v-model="ruleForm.ifShow"-->
<!--              active-color="#51CDCB"-->
<!--              inactive-color="#b8b8b8">-->
<!--          </el-switch>-->
<!--        </el-form-item>-->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { lineHeightStyle } from "@/utils/lineHeight.js";
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import { quillEditor, Quill } from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import { ImageDrop } from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
quillConfig.placeholder = '请输入内容'
import {addCircleAttract,selectCircleAttractList,upCircleAttract,delCircleAttract} from "@/api/square";
export default {
  name: "merchantList",
  components: {
    commonTable,
    customPopconfirm
  },
  props:{
    jurisdiction:{
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      TiLength:0,
      typeList:[{id:1,name:'有项目-找资金'},{id:2,name:'有资金-找项目'},{id:3,name:'有项目、有资金-找地方落户'},{id:4,name:'有项目、有技术、有品牌-找资金'},{id:5,name:'没项目、没技术、没资金-出让土地'},],
      editorOption: quillConfig,
      centerDialogVisible: false,
      ruleForm: {
        title:"",
        details:"",
        goodPhoto:[],
        ifShow:false,
        type:''
      },
      dialogVisible: false,
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        details: [{ required: true, message: "请输入内容", trigger: "blur" }],
        goodPhoto: [{ required: true, message: "请上传图片"}],
        // ifShow: [{ required: true, message: "请选择是否展示单位信息"}],
        type: [{ required: true, message: "请选择类型"}],
      },
      userInfo: this.$store.state.loginRoot.userInfo,
      options: [{ label: "全部", value: 1 }],
      tableData: [],
      filterName: "",
      zsType:"",
      row: "",
      delGoodsT: [],
      currentPage: 1,
      loading:false,
      total: 0, //总条数
      pageNum: 1,
      pageSize: 10,
      uploadUrl: this.$store.state.uploadingUrl,
      dialogImageUrl: "",
      upDate:1,
      circleAttractId:"",
      srcList:[]
    };
  },
  computed: {
    uploadDisabled() {
      return this.ruleForm.goodPhoto.length >= 1;
    },
  },
  mounted(){
    this.queryPage();
  },
  methods: {
    ready() {
      Quill.register({ 'formats/lineHeight': lineHeightStyle }, true);
    },
    onEditorChange(e){
      // e.quill.deleteText(1500,0);
      if(this.ruleForm.details==''){
        this.TiLength=0
      }else{
        this.TiLength=e.quill.getLength()-1
      }
    },
    async queryPage(payload) {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        ...payload
      };
      try {
        this.loading = true;
        const result = await selectCircleAttractList(data);
        this.loading = false;
        const { total, list } = result.data.pageInfo;
        let a = []
        for (let i in list) {
          if (list[i].thumbnail) {
            a.push(list[i].thumbnail)
          }
          list[i].createTime = this.renderTime(list[i].createTime)
          list[i].ifShow = list[i].ifShow==0?true:false
          if (list[i].type == 1) {
            list[i].type = '有项目-找资金'
          }else if (list[i].type == 2) {
            list[i].type = '有资金-找项目'
          }else if (list[i].type == 3) {
            list[i].type = '有项目、有资金-找地方落户'
          }else if (list[i].type == 4) {
            list[i].type = '有项目、有技术、有品牌-找资金'
          }else if (list[i].type == 5) {
            list[i].type = '没项目、没技术、没资金-出让土地'
          }
        }
        this.srcList = a
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    handleSelectionChange(value) {
      this.delGoodsT = value;
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    open(val) {
      this.upDate = 2
      this.centerDialogVisible = true;
      let data = JSON.parse(JSON.stringify(val));
      if (data.thumbnail) {
        this.ruleForm.goodPhoto = data.thumbnail.split(",").map((item, index) => {
          return {
            url: item,
            uid: index,
          };
        });
      }
      this.ruleForm.title = data.title;
      this.ruleForm.details = data.content;
      this.ruleForm.ifShow = data.ifShow;
      // this.ruleForm.type = data.type;
      if (data.type == '有项目-找资金') {
        this.ruleForm.type = 1
      }else if (data.type == '有资金-找项目') {
        this.ruleForm.type = 2
      }else if (data.type == '有项目、有资金-找地方落户') {
        this.ruleForm.type= 3
      }else if (data.type == '有项目、有技术、有品牌-找资金') {
        this.ruleForm.type = 4
      }else if (data.type == '没项目、没技术、没资金-出让土地') {
        this.ruleForm.type= 5
      }
      this.circleAttractId = data.circleAttractId
    },
    viewDetails(row){
      this.$router.push({
        path: `/merchantDetails`,
        query: { circleAttractId: row.circleAttractId },
      });
    },
    //删除弹窗
   async del(val) {
      try {
        await delCircleAttract({ circleAttractId: val });
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    //搜索
    async filterNames() {
      this.currentPage = 1;
      let data = {
        title:this.filterName,
        type:this.zsType
      };
      this.queryPage(data);
    },
    //当前页变更
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    //当前页查询数据条数变更
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    /**@method 模态框关闭的回调 */
    close() {
      (this.ruleForm = {
        title:"",
        details:"",
        goodPhoto:[],
        ifShow:false,
        type:''
      }),
          this.centerDialogVisible = false;
      this.fileList = []
    },
    determine(){
      if(this.ruleForm.goodPhoto.length == 0){
        return this.$message({
          message: "商品图片未上传",
          type: "warning",
          center: true,
        });
      }
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let arr = this.ruleForm.goodPhoto.map((item) => item.url);
            let data = {
              associationId: localStorage.getItem("associationId"),
              userName: localStorage.getItem("name"),
              headerImg: localStorage.getItem("headImg"),
              title:this.ruleForm.title,
              content:this.ruleForm.details,
              thumbnail:arr.join(","),
              // ifShow:this.ruleForm.ifShow?0:1,
              type:this.ruleForm.type,
            };
            this.$message({
              message: "正在保存",
            });
            if (this.upDate == 1) {
              data.ifMoney = 1
              if ( localStorage.getItem("userId")) {
                data.userId = localStorage.getItem("userId")
              }
              await addCircleAttract(data);
            }else {
              data.circleAttractId = this.circleAttractId
              await upCircleAttract(data);
            }
            this.centerDialogVisible = false;
            this.$message({
              message: "保存成功",
              type: "success",
            });
            await this.queryPage();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    handleRemove(file, fileList) {
      this.ruleForm.goodPhoto = fileList;
    },
    // //商品图片
    goodsUpload(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      let arr = [];
      fileList = fileList.map((item) => {
        if (item.response) {
          arr.push({
            url: `https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/${item.response.data}`,
            uid: item.uid,
          });
        } else {
          arr.push(item);
        }
      });
      this.ruleForm.goodPhoto = arr;
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    beforeAvatarUploadImg(file){
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1M!");
      }
      return isLt2M;
    },
  },
};
</script>

<style lang="scss" scoped></style>



